import data from '@/assets/data/valeurs'

import axios from "axios";
const apiPath = "valeur"

export default {
    namespaced: true,
    state: {
        valeurs: data
    },
    mutations: {
        SET_VALEURS(state, _valeur) {
            state.valeurs = _valeur;
        },
    },
    actions: {
        fetchValeurs({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
            return axios
                .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/` + apiPath)
                .then((response) => {
                    commit('SET_VALEURS', response.data)
                })
        },
    },
    getters: {
        getValeurs(state) {
            return state.valeurs;
        },
    },
}