const data = [{
    "id": "64b9027eab821e7966f8f54d",
    "logo": "http://mecs-transition.fr/img/logo_ades.png",
    "description": "Ades Europe est l'association gestionnaire de La M.E.C.S transiton. Elle l'aide dans toutes les tâches transverse que doit supporter Transition",
    "lien": "https://adeseurope.fr",
    "entetePublication": {
        "titre": "A.D.E.S Europe",
        "isShown": true,
        "etablissementId": 25565
    }
},
    {
        "id": "64b9027eab821e7966f8f54e",
        "logo": "http://mecs-transition.fr/img/logo_cnape.png",
        "description": "Nous sommes membres du CNAPE, fédération nationale d'associatons de protection de l'enfance",
        "lien": "https://www.cnape.fr/",
        "entetePublication": {
            "titre": "C.N.A.P.E",
            "isShown": true,
            "etablissementId": 25565
        }
    },
    {
        "id": "64b9027eab821e7966f8f54f",
        "logo": "http://mecs-transition.fr/img/logo_anmecs.png",
        "description": "Transition est membre de l'ANMECS, association nationale des maisons d'enfants à caractère social",
        "lien": "https://www.anmecs.fr/",
        "entetePublication": {
            "titre": "A.N.M.E.C.S",
            "isShown": true,
            "etablissementId": 25565
        }
    },
    {
        "id": "65957b93823aff0df62ab35a",
        "entetePublication": {
            "titre": "Kilya",
            "redacteurId": 88,
            "isShown": false,
            "etablissementId": 25565,
            "nomRedacteur": "PIQUE Gaétan",
            "datePublication": "2024-01-02T23:00:00.000Z",
        },
        "logo": "https://www.kilya.biz/images/logos.png",
        "description": "L'informatique éthique",
        "lien": "https://kilya.biz",
        "_class": "kilya.serenity.partenaire.PartenaireInMongo"
    }]
export default data;
