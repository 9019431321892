<template>
    <s-btn
        class="ma-2"
        variant="text"
        inverted small
        to="/"
    ><v-icon>mdi-home</v-icon></s-btn>
    <s-btn
      v-for="(route, i) in menusPublics" :key="i"
      class="ma-2"
      variant="text"
      inverted small
      :to="route.path"
    >{{ route.libelle }}</s-btn>
</template>

<script>
import { mapGetters } from "vuex";
import SBtn from "@/components/_common/s-btn.vue";

export default {
  name: "menus-publics",
  components: { SBtn },
  computed: {
    ...mapGetters({
      menusPublics: "navMenu/getMenusPublics",
    })
  }
};
</script>