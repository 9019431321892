import data from '@/assets/data/articles'

import axios from "axios";
const apiPath = "article"

export default {
    namespaced: true,
    state: {
        articles: data.data,
        enteteBlog: data.enteteBlog
    },
    mutations: {
        SET_ARTICLES(state, _articles) {
            state.articles = _articles
        },
    },
    getters: {
        getArticles(state) {
            return state.articles;
        },
        getById: (state) =>  (idArticle) => {
            return state.articles.find((i) => i.id === idArticle);
        },
        getEnteteBlog(state) {
            return state.enteteBlog;
        },
    },
    actions: {
        fetchArticles({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
            return axios
                .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/` + apiPath)
                .then((response) => {
                    commit('SET_ARTICLES', response.data)
                })
        },
    },

}