const data = [{
    "id": "64cb8242b65d3f36c9b53799",
    "description": "Compil d'images de superbes montagnes",
    "images": [
        {
            "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fget.pxhere.com%2Fphoto%2Flandscape-nature-wilderness-mountain-cloud-hiking-hill-adventure-valley-mountain-range-highland-mountain-top-ridge-plain-summit-alps-grassland-plateau-fell-loch-landform-mountain-pass-geographical-feature-mountainous-landforms-89202.jpg&f=1&nofb=1&ipt=5a8decf605787344941a705673aa5ebbc3f9af2252bae25de6c093ef7ccd81e2&ipo=images",
            "title": ""
        },
        {
            "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fstatic.pexels.com%2Fphotos%2F414513%2Fpexels-photo-414513.jpeg&f=1&nofb=1&ipt=8efd1c3d62b5d6f531f5f11c6de1756d7dc682e3881a9f91ab45c7aac6231e6c&ipo=images",
            "title": ""
        },
        {
            "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwallpapercave.com%2Fwp%2Fkw2JTsJ.jpg&f=1&nofb=1&ipt=d74b4363cf0ca5720a19c65a9adae808517ab9ebbab2c603d3a9a3219b6884cb&ipo=images",
            "title": ""
        },
        {
            "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.wallpapersden.com%2Fimage%2Fdownload%2Fthe-mountain_bGZnbW6UmZqaraWkpJRobWllrWdma2U.jpg&f=1&nofb=1&ipt=d571f87d899d49a008d4f4973e3fd760dc945601021e84759eeeb383088d4024&ipo=images",
            "title": ""
        },
        {
            "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.7eukgsjOzHGPp095_CAqlAHaE7%26pid%3DApi&f=1&ipt=67b0a9e6b26c0867b7670634769abef4f8bb81b470d747759cf7c1cadfa365e7&ipo=images",
            "title": ""
        },
        {
            "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.OxcgBsshe7jiwcUyGatQAgHaE7%26pid%3DApi&f=1&ipt=c0e03265b8f4c37e87dd2c7494fbfb8e1c0149aa7c4468e0d5f3145e086370af&ipo=images",
            "title": ""
        },
        {
            "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.Hd9GLPpZmC-P0CzvmLIYMAHaE8%26pid%3DApi&f=1&ipt=d54312e5a21d0a8d96647c4acc31116a329cfe0afd4fb38426706568287553e2&ipo=images",
            "title": ""
        }
    ],
    "entetePublication": {
        "titre": "Ma galerie de montagne",
        "isShown": true,
        "etablissementId": 25565,
        "nomRedacteur": "BOBY",
        "datePublication": "2023-08-28T22:00:00.000Z"
    }
},
    {
        "id": "65956e83823aff0df62ab359",
        "entetePublication": {
            "titre": "Les supers dinausores",
            "redacteurId": 88,
            "isShown": false,
            "etablissementId": 25565,
            "nomRedacteur": "PIQUE Gaétan",
            "datePublication": "2024-01-02T23:00:00.000Z"
        },
        "description": "petites compil de mes dinos préférés !",
        "images": [
            {
                "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fa-z-animals.com%2Fmedia%2F2022%2F06%2Fdiplodocus-dinosaur.jpg&f=1&nofb=1&ipt=415cee18f6de01db7a02273969c160e30f5977b7e0f4815f3dcbc8fb4797a0cf&ipo=images",
                "title": "Diplodocus"
            },
            {
                "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.8TQWFNPD2PBNtOLOKXbKRwHaFU%26pid%3DApi&f=1&ipt=b0b9f97786fa590f338f6d420ff817984c0957bc600ef1de51e1d672148012ef&ipo=images",
                "title": "Tricératops"
            },
            {
                "src": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fscitechdaily.com%2Fimages%2FTyrannosaurus-rex.jpg&f=1&nofb=1&ipt=b5162048cf42fe19344a9453795045d6ed2486ddfdd2f0ffab2bf81d1f5699a3&ipo=images",
                "title": "T-rex"
            }
        ],
        "_class": "kilya.serenity.galerie.GalerieInMongo"
    }]
export default data;
