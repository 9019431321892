import data from '@/assets/data/galleries'
import axios from "axios";
const apiPath = "galerie"

export default {
    namespaced: true,
    state: {
        galeries: data

    },
    mutations: {
        SET_GALERIES(state, _galleries) {
            state.galeries = _galleries;
        },
    },
    actions: {
        fetchGaleries({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
            return axios
                .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/` + apiPath)
                .then((response) => {
                    commit('SET_GALERIES', response.data)
                })
        },
    },
    getters: {
        getGaleries(state) {
            return state.galeries;
        },
    },
}