<template>
  <v-btn
    :class="'text-left ' +(small ? 'button-small ': 'button ') + (inverted ? 'text-surface' : 'text-primary')"
    :color="inverted ? 'primary' :'surface'"
    :to="to"
    :href="href"
    :variant="variant"
  ><p><slot></slot></p></v-btn>
</template>

<script>
export default {
  name: "s-btn",
  props: {
    to: {
      type: String,
    },
    href: {
      type: String,
    },
    variant: {
      type: String
    },
    inverted: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  margin: 2px;
  align-content: center;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 15px;
  font-weight: 500;
  line-height: normal;
}
.button-small {
  padding: 5px;
  align-content: center;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  border-radius: 15px;
  font-weight: 400;
  font-size: 8px !important;
}

@media only screen and (max-width: 767px) {
  p {
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1424px) {
  p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1424px) {
  p {
    font-size: 16px;
  }
}
</style>