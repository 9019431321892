import { createRouter, createWebHistory } from "vue-router";
import Article from "@/views/public/article.vue";

const routes = [
  {
    path: "/",
    name: "accueil",
    component: function() {
      return import("../views/public/accueil.vue");
    },
  },
  {
    path: "/test",
    name: "test",
    component: function() {
      return import(  "../views/test.vue");
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: function() {
      return import(  "../views/public/blog.vue");
    },
  },
  {
    path: '/article/:idArticle',
    name: 'article',
    component: Article,
    props: true
  },
  {
    path: "/actualites",
    name: "actualites",
    component: function() {
      return import(  "../views/public/actualites.vue");
    },
  },
  {
    path: "/a-propos",
    name: "a-propos",
    component: function () {
      return import(  "../views/public/a-propos.vue");
    },
  },
  {
    path: "/services",
    name: "services",
    component: function () {
      return import(  "../views/public/services.vue");
    },
  },
  {
    path: "/galeries",
    name: "galeries",
    component: function () {
      return import(  "../views/public/galeries.vue");
    },
  },
  {
    path: "/recrutement",
    name: "recrutement",
    component: function () {
      return import(  "../views/public/recrutement.vue");
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: function () {
      return import(  "../views/public/contact.vue");
    },
  },
  {
    path: '/:catchAll(.*)*',
    component: function () {
      return import(  "../views/404.vue");
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },

});

export default router;
