const fiche = {
    "id": "64b900c0ab821e7966f8f540",
    "adresse": "109 avenue de Lespinet",
    "numeroDeTelephone": "05 61 14 76 90",
    "adresseMail": "secreteriat.transition@adeseurope.fr",
    "logo": "http://mecs-transition.fr/img/logo_transition_2020.png",
    "coordonnes": {
        "longitude": 1.468943,
        "latitude": 43.579454
    },
    "codePostal": "31410",
    "complementAdresse": "Bâtiment C",
    "ville": "Toulouse",
    "entetePublication": {
        "titre": "M.E.C.S Transition",
        "etablissementId": 25565
    }
}
const htmlHeadline = "Nous <strong>favorisons l'insertion</strong> grâce à un soutien <strong>éducatif, psycologique et matériel.</strong>"
const mission = {
    titre: "Qui accueillons nous ?",
        contenu: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.",
}
const projet = {
    axes: [
        {
            ordre: 1,
            contenu: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
        },
        {
            ordre: 2,
            contenu: "Un professeur du Hampden-Sydney College, en Virginie, s'est intéressé à un des mots latins les plus obscurs, consectetur, extrait d'un passage du Lorem Ipsum."
        },
        {
            ordre: 3,
            contenu: "Plusieurs variations de Lorem Ipsum peuvent être trouvées ici ou là, mais la majeure partie d'entre elles a été altérée par l'addition d'humour ou de mots aléatoires qui ne ressemblent pas une seconde à du texte standard."
        },
        {
            ordre: 4,
            contenu: "On sait depuis longtemps que travailler avec du texte lisible et contenant du sens est source de distractions, et empêche de se concentrer sur la mise en page elle-même. L'avantage du Lorem Ipsum sur un texte générique comme 'Du texte. Du texte. Du texte.'"
        }
    ],
        liens: [
        {
            src: "",
            title: "Télecharger le projet (PDF)"
        },
    ]
}
const admission = {
    titre: "Dispositif d'admissibilité",
        contenu: "L'admission constiitue l'acte fondateur du contrat qui va lier les protagonistes du projet.\nElle comporte deux phases.",
        disclaimer: "Ces admisions ne comportent aucuns restrictions et s'inscrivent dans le cadre de ...",
        etapes: [
        {
            ordre: 1,
            titre: "Admission",
            contenu: "La rencontre d'admission avec l'équipe de direction qui détermine la réalité de l'admission"
        },
        {
            ordre: 2,
            titre: "Accueil",
            contenu: "La rencontre d'accueil avec l'équipe éducative qui vient marquer les premiers échanges entre éducateurs et le jeune."
        },
    ],
}
const contact = {
    texte: "Vous avez des quentions sur notre projet ou souraitez faire remonter une information ?",
    texteBoutonMail: "Je prends contact",
    numeroDeTelephone: "05 61 14 76 90",
    mail: "secreteriat.transition@adeseurope.fr"
}
export default { htmlHeadline, fiche, mission, projet, admission, contact };
