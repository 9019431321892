<template>
    <v-row class="pb-2">
      <s-btn class="text-left" width="100%" :variant="variant" inverted to="/">Accueil</s-btn>
    </v-row>
    <v-row class="pb-2" v-for="(item, index) in menusPublics" :key="index">
      <s-btn class="text-left" :width="width" :variant="variant" :inverted="inverted" :to="item.path">{{ item.libelle }}</s-btn>
    </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import SBtn from "@/components/_common/s-btn.vue";

export default {
  name: "liste-menus-publics",
  components: { SBtn },
  computed: {
    ...mapGetters({
      menusPublics: "navMenu/getMenusPublics",
    })
  },
  props: {
    width: {
      type: String,
      default: "100%"
    },
    inverted: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: "tonal"
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>

</style>