import data from '@/assets/data/actualites.js'

import axios from "axios";
const apiPath = "actualite"
export default {
    namespaced: true,
    state: {
        actualites: data
    },
    mutations: {
        SET_ACTUALITES(state, _actualites) {
            state.actualites = _actualites;
        },
    },
    actions: {
        fetchActualites({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
            return axios
              .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/` + apiPath)
              .then((response) => {
                  commit('SET_ACTUALITES', response.data)
              })
        },
    },
    getters: {
        getActualites(state) {
            return state.actualites;
        },
    },
}