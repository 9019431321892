import data from '@/assets/data/partenaires'
import axios from "axios";

export default {
    namespaced: true,
    state: {
        partenaires: data
    },
    mutations: {
        SET_PARTENAIRES(state, _partenaires) {
            state.partenaires = _partenaires;
        }
    },
    actions: {
        fetchPartenaires({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
            return axios
                .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/partenaire`)
                .then((responce) => {
                    commit('SET_PARTENAIRES', responce.data)
                })
        }
    },
    getters: {
        getPartenaires(state) {
            return state.partenaires;
        },
    },
}