<template>
  <v-container class="section">
    <s-btn inverted class="ma-4" to="/blog">Retour au blog</s-btn>
    <div v-if="loaded && article !== undefined">
      <s-big-title class="py-6" center>{{ article.entetePublication.titre }}</s-big-title>
      <p class="text-center">
        <v-chip class="mx-2" color="primary"><p>{{ prettyDate(article.entetePublication.datePublication) }}</p></v-chip>
        <v-chip class="mx-2" color="primary"><p>{{ article.entetePublication.nomRedacteur }}</p></v-chip>
      </p>
      <v-container>
        <div v-html="article.htmlContenu"></div>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import SBigTitle from "@/components/_common/s-big-title.vue";
import { mapActions, mapGetters } from "vuex";
import SBtn from "@/components/_common/s-btn.vue";
import moment from "moment/moment";

export default {
  components: { SBtn, SBigTitle },
  data() {
    return {
      loaded: false
    };
  },
  props: {
    idArticle: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getArticle: "article/getById"
    }),
    article() {
      return this.getArticle(this.idArticle);
    }
  },
  methods: {
    ...mapActions({
      fetchArticles: "article/fetchArticles"
    }),
    prettyDate(date) {
      moment.locale('fr')
      return moment(date).format('LL')
    }
  },
  created() {
    this.fetchArticles()
      .then(this.loaded = true)
  }
};
</script>

<style lang="scss" scoped>
.section {
  min-height: 60vh;
}
@media only screen and (max-width: 767px) {
  .v-container {
    max-width: 98%
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .v-container {
    max-width: 90%
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1424px) {
  .v-container {
    max-width: 75%
  }
}
@media only screen and (min-width: 1424px) {
  .v-container {
    max-width: 60%
  }
}
</style>