<template>
  <v-sheet rounded class="pa-2">
    <h4>Contact</h4>
    <p class="pb-2">
      <v-chip on-click="" :href="'tel:' + contact.numeroDeTelephone" append-icon="mdi-open-in-new">{{ contact.numeroDeTelephone }}</v-chip>
    </p>
    <p class="pb-2">
      <v-chip on-click="" :href="'mailto:'+ contact.mail" append-icon="mdi-open-in-new">{{ contact.mail }}</v-chip>
    </p>
  </v-sheet>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  p {
    font-size: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1424px) {
  p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1424px) {
  p {
    font-size: 16px;
  }
}
</style>