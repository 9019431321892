<template>
  <v-app-bar density="comfortable" >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      color="primary"
      @click="drawer = !drawer">
    </v-app-bar-nav-icon>
    <v-app-bar-title>
      <div class="app-bar-logo-container">
        <router-link to="/"><img :src="require('@/assets/logo-header/logo_transition.png')" alt="logo transition"/></router-link>
        <a target="_blank" href="https://adeseurope.fr/"><img class="img-optionnal" :src="require('@/assets/logo-header/logo_ades.png')" alt="logo ades"/></a>
      </div>
    </v-app-bar-title>
    <template v-slot:append v-if="!drawer">
      <div class="d-none d-md-flex">
        <menus-publics class="d-none d-md-flex"></menus-publics>
      </div>
    </template>
  </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    temporary
  >
    <v-col>
      <liste-menus-publics :inverted="true"></liste-menus-publics>
    </v-col>
  </v-navigation-drawer>
</template>

<script>

import ListeMenusPublics from "@/components/_common/navigation/liste-menus-publics.vue";
import MenusPublics from "@/components/_common/navigation/menus-publics.vue";

export default {
  components: { MenusPublics, ListeMenusPublics },
  data () {
    return {
      drawer: false,
      tab: null
    }
  }
};
</script>

<style scoped>
.v-app-bar-title {
  text-overflow: "";
}

.app-bar-logo-container {
  display: flex;
  align-items: center;
}

img {
  padding-top: 0.25vh;
  padding-right: 25px;
  height: 5.5vh;
}

.img-optionnal {
  height: 2.5vh;
}


@media only screen and (min-width: 960px) and (max-width: 1100px) {
  .img-optionnal {
    display: none;

  }
  img {
    padding-right: 0;
    height: 4vh;
  }
}

</style>