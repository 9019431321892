const services = [{
    "id": "64b90239ab821e7966f8f549",
    "entetePublication": {
        "titre": "Internat",
        "isShown": true,
        "etablissementId": 25565
    },
    "description": "C’est un lieu d’accueil collectif mixte (de 6 places + 1 chambre relais) qui a vocation à supporter chaque trajectoire personnelle. Le cadre de vie se veut de type familial et favorise la rencontre avec les autres jeunes et adultes ainsi que la référence à la norme sociale. L’accompagnement dans la vie collective est un élément important de l’action éducative. Celle-ci se fait aussi dans l’élaboration du parcours personnel et dans les accompagnements que celui-ci nécessite (scolarité, formation, travail, loisirs, culture, soins…). Ces différentes inscriptions (individuelles et collectives) permettent à chacun le tissage de son propre lien social.",
    "questions": [
        {
            "titre": "Pour qui ?",
            "reponse": "Les jeunes de 10 à 15 ans"
        },
        {
            "titre": "Combien de places ?",
            "reponse": "6 places sont disponibles ainsi qu'une chambre relais"
        }
    ],
    "images": [
        {
            "src": "http://mecs-transition.fr/img/internat_1.jpg",
            "title": ""
        },
        {
            "src": "http://mecs-transition.fr/img/internat_2.jpg",
            "title": ""
        },
        {
            "src": "http://mecs-transition.fr/img/internat_3.jpg",
            "title": ""
        },
        {
            "src": "http://mecs-transition.fr/img/internat_4.jpg",
            "title": ""
        },
        {
            "src": "http://mecs-transition.fr/img/internat_5.jpg",
            "title": ""
        },
        {
            "src": "http://mecs-transition.fr/img/internat_6.jpg",
            "title": ""
        }
    ],
    "lienProjet": "http://mecs-transition.fr/img/Projet%20de%20service%20INTERNAT%20-%20MECS%20Transition%20-%202021-2026.pdf"
},
    {
        "id": "64b90239ab821e7966f8f54a",
        "entetePublication": {
            "titre": "Studios intégrés",
            "isShown": true,
            "etablissementId": 25565
        },
        "description": "Installés dans une maison rassemblant 7 studios mixtes, des espaces communs (salle à manger, salon, cuisine) et les bureaux des éducateurs, de la maîtresse de maison et du psychologue. Ils constituent un hébergement individuel (avec kitchenette et douche) où chaque jeune dispose d’un lieu qui lui est confié pour y loger. Une vie collective peut s’y développer au gré de chacun. Elle est « à géométrie variable » en fonction des jeunes accueillis mais les règles communes qui la régulent restent constantes. L’accompagnement individuel y est comparable à celui de l’internat mais l’espace de tissage n’y est pas le même car la collectivité ne s’y impose pas mais se fabrique au quotidien.",
        "questions": [
            {
                "titre": "Pour qui ?",
                "reponse": "Les jeunes de 16 à 21 ans"
            },
            {
                "titre": "Combien de places ?",
                "reponse": "7 places sont disponibles"
            }
        ],
        "images": [
            {
                "src": "http://mecs-transition.fr/img/studios_1.jpg",
                "title": ""
            },
            {
                "src": "http://mecs-transition.fr/img/studios_2.jpg",
                "title": ""
            },
            {
                "src": "http://mecs-transition.fr/img/studios_3.jpg",
                "title": ""
            },
            {
                "src": "http://mecs-transition.fr/img/studios_4.jpg",
                "title": ""
            },
            {
                "src": "http://mecs-transition.fr/img/studios_5.jpg",
                "title": ""
            }
        ],
        "lienProjet": "http://mecs-transition.fr/img/Projet%20de%20service%20STUDIOS%20ST%20DENIS%20-%20MECS%20Transition%20-%202021-2026.pdf"
    },
    {
        "id": "64b90239ab821e7966f8f54b",
        "entetePublication": {
            "titre": "Studios semi-autonomes",
            "isShown": true,
            "etablissementId": 25565
        },
        "description": "Au sein d’un immeuble à 200 mètres du métro Saint-Agne, l’accueil des jeunes garçons et filles porteur d’un projet de formation ou professionnel, chacun se retrouvant dans de véritables appartements tout équipés. L’éducateur présent fait le lien avec les jeunes pour consolider leur parcours. Un veilleur est présent afin d’assurer la tranquillité et la sécurité de tous. Un psychologue est également présent afin de soutenir les jeunes dans l’avancement de leur projet même lorsqu’il y a des difficultés.",
        "questions": [
            {
                "titre": "Pour qui ?",
                "reponse": "Les jeunes de 15 à 18 ans"
            },
            {
                "titre": "Combien de places ?",
                "reponse": "7 places sont disponibles"
            }
        ],
        "images": [
            {
                "src": "http://mecs-transition.fr/img/studios_sa_1.jpg",
                "title": ""
            },
            {
                "src": "http://mecs-transition.fr/img/studios_sa_2.jpg",
                "title": ""
            },
            {
                "src": "http://mecs-transition.fr/img/studios_sa_3.jpg",
                "title": ""
            }
        ],
        "lienProjet": "http://mecs-transition.fr/img/Projet%20de%20service%20STUDIOS%20ST%20AGNE%20-%20MECS%20Transition%20-%202021-2026.pdf"
    },
    {
        "id": "64b90239ab821e7966f8f54c",
        "entetePublication": {
            "titre": "Service extérieur autonome",
            "isShown": true,
            "etablissementId": 25565
        },
        "description": "Il permet à chacun d’être accueilli dans un des 23 appartements individuels en ville tout en étant pris en charge par une institution. Ce service offre donc une possibilité pour des jeunes de se voir accompagnés dans leurs derniers pas vers le mythe de se débrouiller tout seul. Bien sûr, le quotidien de la cité les exposera à des rencontres et des contraintes qui ne seront pas moindres qu’en structure collective, mais différentes. Elles leur permettront de travailler l’aménagement de leur rapport aux autres. L’essentiel de l’accompagnement individuel est le même que pour les autres services. L’immersion en milieu ordinaire, soutenue par l’exigence institutionnelle encadre ce type de placement, favorise l’émergence de questions sur l’avenir, le possible, l’impossible.",
        "questions": [
            {
                "titre": "Pour qui ?",
                "reponse": "Les jeunes de 16 à 21 ans"
            },
            {
                "titre": "Combien de places ?",
                "reponse": "7 places sont disponibles"
            }
        ],
        "images": [],
        "lienProjet": "http://mecs-transition.fr/img/Projet%20de%20service%20SE%20-%20MECS%20Transition%20-%202021-2026.pdf"
    },
    {
        "id": "659567f7823aff0df62ab358",
        "entetePublication": {
            "titre": "Le service fantôme",
            "redacteurId": 88,
            "isShown": false,
            "etablissementId": 25565,
            "nomRedacteur": "PIQUE Gaétan",
            "datePublication": "2024-01-02T23:00:00.000Z",
        },
        "description": "Bob",
        "questions": [
            {
                "titre": "Pour qui ?",
                "reponse": "Les jeunes de 16 à 21 ans"
            },
            {
                "titre": "Combien de places ?",
                "reponse": "7 places sont disponibles"
            }
        ],
        "images": [],
        "lienProjet": ""
    }]
const htmlEnteteService = "<p>Quatre services sont disponibles pour les jeunes, afin de les accompagner au mieux dans leur transition</p>"

export default { services, htmlEnteteService: htmlEnteteService };
