const datesCles = [{
    "id": "65f9b68c60e700d8e7df609d",
    "entetePublication": {
        "titre": "2003",
        "redacteurId": 88,
        "etablissementId": 25565,
        "nomRedacteur": "COUTURIER Quentin",
        "datePublication": "2024-01-02T23:00:00.000Z",
    },
    "contenu": "Plus ou moins ma date de naissance, j'était pas trop inspiré"
},
    {
        "id": "65f9b6dc60e700d8e7df609e",
        "entetePublication": {
            "titre": "413 av.JC",
            "redacteurId": 88,
            "etablissementId": 25565,
            "nomRedacteur": "COUTURIER Quentin",
            "datePublication": "2024-01-02T23:00:00.000Z",
        },
        "contenu": "Ca aussi c'est une date mais j'y était pas, doit y avoir eu des trucs cool à ce moment là !"
    }]
export default { datesCles };
