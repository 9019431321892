<template>
  <div class="py-5">
    <p class="pb-1">Designé par Ombeline de <v-chip target="_blank" href="https://bootsandcats.agency" append-icon="mdi-open-in-new">BootsAndCats</v-chip></p>
    <p class="pb-1">Réalisé par Quentin de <v-chip target="_blank" href="https://www.kilya.biz/" append-icon="mdi-open-in-new">Kilya, l'informatique éthique</v-chip></p>
    <p>© MECS Transition 2015-2023</p>
  </div>
</template>

<script>
export default {
  name: "mentions-legales",
  props: {
    color: {
      type: String,
      default: ""
    },
  }
};
</script>