import data from '@/assets/data/fichesServices'
import axios from "axios";
const apiPath = "ficheService"

export default {
    namespaced: true,
    state: {
        services: data.services,
        htmlEnteteService: data.htmlEnteteService
    },
    mutations: {
        SET_SERVICES(state, _services) {
            state.services = _services;
        },
    },
    actions: {
        fetchServices({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
            return axios
                .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/` + apiPath)
                .then((response) => {
                    commit('SET_SERVICES', response.data)
                })
        },
    },
    getters: {
        getServices(state) {
            return state.services;
        },
        getHtmlEnteteService(state) {
            return state.htmlEnteteService;
        },
    },
}