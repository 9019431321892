<template>
  <div :class="center ? 'center':''">
    <h2 :class="(inverted ? 'inverted' : 'classic') + ' ' + (small ? 'text-small' : 'text')">
      <slot></slot>
    </h2>
  </div>
</template>

<script>
export default {
  name: "s-big-title",
  props: {
    inverted: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss"  scoped>
div {
  display: flex;
}
.center {
  justify-content: center;
}

.classic {
  color: rgb(var(--v-theme-primary));
}
.inverted {
  color: rgb(var(--v-theme-surface));
}
h2 {
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  text-align: center;
  transition: 0.3s; /* Add smooth transition to the underline */
}

.classic:hover {
  color: rgb(var(--v-theme-surface));
  background: rgb(var(--v-theme-primary));
}
.inverted:hover {
  color: rgb(var(--v-theme-primary));
  background: rgb(var(--v-theme-surface));
}


@media only screen and (max-width: 767px) {
  .text {
    font-size: 48px;
    font-weight: bold;
  }
  .text-small {
    font-size: 32px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .text {
    font-size: 64px;
    font-weight: bold;
  }
  .text {
    font-size: 48px;
    font-weight: bold;
  }

}
@media only screen and (min-width: 1024px) {
  .text {
    font-size: 86px;
    font-weight: bold;
  }
  .text {
    font-size: 64px;
    font-weight: bold;
  }
}
</style>
