import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      options: { customProperties: true },
      light: {
        dark: false,
        variables: {}, // ✅ this property is required to avoid Vuetify crash

        colors: {
          // Workaround: Custom colors seem to erase default colors, so we need to include the default colors (of `light` or `dark` theme)
          background: '#f5f5f5',
          surface: '#f5f5f5',
          primary: '#DF252B',
          secondary: '#03DAC5',
          'secondary-darken-1': '#03DAC5',
          error: '#CF6679',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00'
        },
      },
    }
  }
})

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount("#app");
