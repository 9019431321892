export default {
    namespaced: true,
    state: {
        menusPublic: [
            {name:"a-propos", libelle:"À propos", path:"/a-propos"},
            {name:"blog", libelle:"Blog", path:"/blog"},
            {name:"actualites", libelle:"Actualités", path:"/actualites"},
            {name:"services", libelle:"Services",path: "/services"},
            {name:"galeries", libelle:"Galeries", path: "/galeries"},
            {name:"recrutement", libelle:"Recrutement", path: "/recrutement"},
            {name:"contact", libelle: "Contact", path: "/contact"},
        ],
    },
    getters: {
        getMenusPublics(state) {
            return state.menusPublic;
        },
    },

}