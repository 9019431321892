const data = [{
    "id": "65f9aa6b60e700d8e7df6098",
    "entetePublication": {
        "titre": "Enfants pris en charge",
        "redacteurId": 88,
        "isShown": true,
        "etablissementId": 25565,
        "nomRedacteur": "PIQUE Gaétan",
        "datePublication": "2024-01-02T23:00:00.000Z",
    },
    "chiffre": 88
},
    {
        "id": "65f9aa9760e700d8e7df609a",
        "entetePublication": {
            "titre": "Projets artistiques en cours",
            "redacteurId": 88,
            "etablissementId": 25565,
            "nomRedacteur": "PIQUE Gaétan",
            "datePublication": "2024-01-02T23:00:00.000Z",
        },
        "chiffre": 5
    },
    {
        "id": "65f9aac960e700d8e7df609b",
        "entetePublication": {
            "titre": "Etablissements d'accueil",
            "redacteurId": 88,
            "etablissementId": 25565,
            "nomRedacteur": "PIQUE Gaétan",
            "datePublication": "2024-01-02T23:00:00.000Z",
        },
        "chiffre": 11
    }]
const imageChiffresCle = "https://serenity-web-content.ams3.digitaloceanspaces.com/img-baniere-chiffres.jpeg"
export default { data, imageChiffresCle };
