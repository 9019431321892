import data from '@/assets/data/chiffresCles'

import axios from "axios";
const apiPath = "chiffre-cle"

export default {
    namespaced: true,
    state: {
        chiffres: data.data,
        imageChiffresCle: data.imageChiffresCle
    },
    mutations: {
        SET_CHIFFRES(state, _chiffre) {
            state.chiffres = _chiffre;
        },
    },
    actions: {
        fetchChiffres({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
            return axios
                .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/` + apiPath)
                .then((response) => {
                    commit('SET_CHIFFRES', response.data)
                })
        },
    },
    getters: {
        getChiffres(state) {
            return state.chiffres;
        },
        getImageChiffres(state) {
            return state.imageChiffresCle;
        },
    },
}