import data from '@/assets/data/fichesEtablissement'
import axios from "axios";

export default {
    namespaced: true,
    state: {
        htmlHeadline: data.htmlHeadline,
        fiche: data.fiche,
        admission: data.admission,
        mission: data.mission,
        projet: data.projet,
        contact: data.contact
    },
    mutations: {
        SET_FICHE_ETABLISSEMENT(state, _etablissement) {
            state.fiche = _etablissement;
        },
    },
    actions: {
        fetchFicheEtablissement({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
                return axios
                    .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/ficheEtablissement`)
                    .then((responce) => {
                        commit('SET_FICHE_ETABLISSEMENT', responce.data)
                    })
        },
    },
    getters: {
        getFicheEtablissement(state) {
            return state.fiche;
        },
        getAdmissionEtablissement(state) {
            return state.admission;
        },
        getMissionEtablissement(state) {
            return state.mission;
        },
        getProjetEtablissement(state) {
            return state.projet;
        },
        getContactEtablissement(state) {
            return state.contact;
        },
        getHistoireEtablissement(state) {
            return state.historique;
        },
        getHtmlHeadline(state) {
            return state.htmlHeadline;
        }
    },
}