<template>
  <v-app>
    <v-main>
      <barre-navigation></barre-navigation>
      <router-view></router-view>
      <custom-footer :etablissement="etablissement" :contact="contact"  border>2023 - Kilya</custom-footer>
    </v-main>
  </v-app>
</template>

<script>

import BarreNavigation from "@/components/_common/navigation/barre-navigation.vue";
import CustomFooter from "@/components/_common/navigation/custom-footer.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: { CustomFooter, BarreNavigation },
  computed: {
    ...mapGetters({
      menusPublics: "navMenu/getMenusPublics",
      etablissement: "etablissement/getFicheEtablissement",
      contact: "etablissement/getContactEtablissement",
    })
  },
  methods: {
    ...mapActions({
      fetchEtablissement: "etablissement/fetchFicheEtablissement"
    })
  },
  created() {
    this.fetchEtablissement()
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Oswald:wght@300;400;500;700&display=swap');
router-view {
  width: 100%;
}

.section {
  margin-bottom: 10vh;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}
button {
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
p {
  font-family: 'Inter', sans-serif;
  line-height: 1.3;
}
h1 {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}
h2 {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
}
h3 {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
}
h3 {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
    font-weight: normal;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
  }
  h4 {
    font-size: 14px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
  }
  .section {
    margin-bottom: 6vh;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  h1 {
    font-size: 56px;
    font-weight: normal;
    text-transform: uppercase;
  }
  h2 {
    font-size: 34px;
    font-weight: bold;
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 16px;
  }
  .section {
    margin-bottom: 8vh;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1424px) {
  h1 {
    font-size: 64px;
    font-weight: normal;
  }
  h2 {
    font-size: 40px;
    font-weight: bold;
  }
  h3 {
    font-size: 20px;
    font-weight: bold;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1424px) {
  h1 {
    font-size: 86px;
    font-weight: normal;
  }
  h2 {
    font-size: 44px;
    font-weight: bold;
  }
  h3 {
    font-size: 28px;
    font-weight: bold;
  }
  h4 {
    font-size: 22px;
    font-weight: bold;
  }
  p {
    font-size: 20px;
  }
}
</style>
