import data from '@/assets/data/offresEmploi'

import axios from "axios";
const apiPath = "offreEmploi"

export default {
    namespaced: true,
    state: {
        offresEmploi: data.offres,
        recrutement: data.recrutement
    },
    mutations: {
        SET_OFFRES_EMPLOI(state, _offres) {
            state.offresEmploi = _offres;
        },
    },
    actions: {
        fetchOffresEmploi({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
                return axios
                    .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/` + apiPath)
                    .then((response) => {
                        commit('SET_OFFRES_EMPLOI', response.data)
                    })
        },
    },
    getters: {
        getOffresEmploi(state) {
            return state.offresEmploi;
        },
        getRecrutement(state) {
            return state.recrutement;
        },
    },
}