const data = [{
    "id": "659c3871c2d6118159e4be89",
    "entetePublication": {
        "titre": "Respect de la personne humaine dans toutes ses composantes",
        "etablissementId": 25565
    }
},
    {
        "id": "659c3878c2d6118159e4be8a",
        "entetePublication": {
            "titre": "Reget du racisme et de la xénophobie",
            "etablissementId": 25565
        }
    },
    {
        "id": "659c387fc2d6118159e4be8b",
        "entetePublication": {
            "titre": "Aide et solidarité aux enfants, adolescents et adultes en difficulté",
            "etablissementId": 25565
        }
    },
    {
        "id": "659c38b3c2d6118159e4be8c",
        "entetePublication": {
            "titre": "Un engagement militant et laïque",
            "etablissementId": 25565
        }
    }]
export default data;
