import { createStore } from "vuex";
import actualite from "@/store/modules/actualite";
import navMenu from "@/store/modules/navMenu";
import article from "@/store/modules/article";
import etablissement from "@/store/modules/etablissement";
import galerie from "@/store/modules/galerie";
import partenaire from "@/store/modules/partenaire";
import service from "@/store/modules/service";
import valeures from "@/store/modules/valeures";
import offresEmploi from "@/store/modules/offresEmploi";
import chiffesCles from "@/store/modules/chiffres-cles";
import datesCles from "@/store/modules/dates-cles";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    actualite,
    article,
    galerie,
    navMenu,
    partenaire,
    valeures,
    service,
    etablissement,
    offresEmploi,
    chiffesCles,
    datesCles
  },
});
