import data from '@/assets/data/datesCles'

import axios from "axios";
const apiPath = "date-cle"

export default {
    namespaced: true,
    state: {
        dates: data.datesCles
    },
    mutations: {
        SET_DATES(state, _date) {
            state.dates = _date;
        },
    },
    actions: {
        fetchDates({ commit }) {
            if (`${process.env.VUE_APP_ENV_IS_STANDALONE}` !== "true")
            return axios
                .get(`${process.env.VUE_APP_URL_BACK_SERENITY}/` + apiPath)
                .then((response) => {
                    commit('SET_DATES', response.data)
                })
        },
    },
    getters: {
        getDates(state) {
            return state.dates;
        },
    },
}