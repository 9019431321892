<template>
  <v-footer color="primary">
    <v-container>
      <v-row justify="space-around">
        <v-col sm="10" md="5">
          <liste-menus-publics width="100%" variant="flat"></liste-menus-publics>
        </v-col>
        <v-col sm="10" md="5">
          <text-adresse color="primary" class="text-center ma-1" :etablissement="etablissement"></text-adresse>
          <text-contact color="primary" class="text-center ma-1" :contact="contact"></text-contact>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center" >
          <mentions-legales color="primary"></mentions-legales>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import ListeMenusPublics from "@/components/_common/navigation/liste-menus-publics.vue";
import TextAdresse from "@/components/contact/text-adresse.vue";
import TextContact from "@/components/contact/text-contact.vue";
import MentionsLegales from "@/components/_common/mentions-legales/carte-kilya.vue";

export default {
  name: "custom-footer",
  components: {MentionsLegales, TextContact, TextAdresse, ListeMenusPublics },
  props: {
    etablissement: {
      type: Object,
      required: true
    },
    contact: {
      type: Object,
      required: true
    }
  },
};
</script>
