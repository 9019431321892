<template>
  <v-sheet class="pa-2">
    <h4>Adresse</h4>
    <p>{{ etablissement.adresse }}</p>
    <p>{{ etablissement.complementAdresse }}</p>
    <p>{{ etablissement.codePostal }} {{ etablissement.ville }}</p>
  </v-sheet>
</template>

<script>
export default {
  props: {
    etablissement: {
      type: Object,
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  p {
    font-size: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1424px) {
  p {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1424px) {
  p {
    font-size: 16px;
  }
}
</style>