const data = [{
    "id": "659534383b8d8e0b4e87eb18",
    "entetePublication": {
        "titre": "Bob dessine des dino !",
        "redacteurId": 88,
        "isShown": true,
        "etablissementId": 25565,
        "nomRedacteur": "PIQUE Gaétan",
        "datePublication": "2024-01-02T23:00:00.000Z"
    },
    "dateEvenement": "2024-01-02T23:00:00.000Z",
    "description": "Aujourd'hui, nous avons fait un atelier dessin avec les enfants. Bob a bien progressé, à coté de sa maison il a dessiné un dino. :)",
    "image": {},
    "_class": "kilya.serenity.actualite.ActualiteInMongo"
},
    {
        "id": "6595362d3b8d8e0b4e87eb19",
        "entetePublication": {
            "titre": "Sortie dans le bois",
            "redacteurId": 88,
            "isShown": true,
            "etablissementId": 25565,
            "nomRedacteur": "PIQUE Gaétan",
            "datePublication": "2024-01-02T23:00:00.000Z"
        },
        "dateEvenement": "2024-01-03T23:00:00.000Z",
        "description": "Aujourd'hui nous sommes allés au bois pour prendre un peu l'air frais.",
        "image": {},
        "_class": "kilya.serenity.actualite.ActualiteInMongo"
    },
    {
        "id": "65a533d15d9a490e96e383ac",
        "entetePublication": {
            "titre": "Grand rangement à Kilya !",
            "redacteurId": 88,
            "isShown": true,
            "etablissementId": 25565,
            "nomRedacteur": "PIQUE Gaétan",
            "datePublication": "2024-01-14T23:00:00.000Z"
        },
        "dateEvenement": "2024-01-10T23:00:00.000Z",
        "description": "Nous avons fait un peu de rangement Jeudi dernier à Kilya. En plus des Disques d’installation de Windows Vista et XP nous avons déniché une vielle pancarte de l'entreprise ! (photo à l'appui)",
        "image": {
            "title": "Ancienne pancarte de Kilya",
            "src": "https://serenity-web-content.ams3.cdn.digitaloceanspaces.com/ancienne-pancarte-kilya.jpg"
        },
        "_class": "kilya.serenity.actualite.ActualiteInMongo"
    }]
export default data;
